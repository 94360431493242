@import "/src/assets/variables.scss";

.header-nav {
  background-color: $themeGrey;
  max-width: 100vw;
  &_demo {
    background-color: $themeBlue !important;
    &_alert {
      background-color: $themeWhite;
      border-radius: 100%;
      filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.4));
      img {
        width: 32px !important;
        height: 32px !important;
        max-width: 32px !important;
        padding: 4px;
      }
    }
  }
  &_container {
    .menu-button {
      background-color: $themeDark;
      height: 48px;
      min-width: 48px;
      &.active {
        background-color: $themeWhite !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    .play-alert {
      background-color: $themeLightYellow;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
}

.menu-content {
  &::before {
    content: "";
    position: absolute;
    top: -12px;
    right: 0;
    background-color: $themeWhite;
    width: 48px;
    height: 24px;
  }
}
