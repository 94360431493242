@import "/src/assets/variables.scss";

.games-played {
  &_container {
    .played-item {
      &_dark {
        background-color: $themeDark !important;
        strong {
          color: $themeWhite !important;
        }
        img {
          border: 1px solid rgba(34, 34, 34, 0.2);
        }
      }
      &_light {
        background-color: $themeWhite !important;
      }
    }

    .icon-played-game {
      background-color: $themeWhite;
      border-radius: 5px;
      width: 5vw;
      max-width: 56px;
      min-width: 36px;
    }

    .donut-chart {
      font-size: 36px;
      @media (min-width: $mediaTablet) {
        font-size: 48px;
      }
    }

    strong {
      word-wrap: break-word;
      @media (max-width: $mediaMediumMobile) {
        font-size: 0.8rem;
        line-height: 0.9rem;
      }
    }
  }
}
