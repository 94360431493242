@import '/src/assets/variables.scss';

.word-widget-container {
    padding-bottom: 16px;

    &__content {
        height: 240px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        background-color: #{$themeGrey}80;
        border-radius: 10px;
        padding: 10px;
        margin: 5px 0;
        height: 38px;

        &:hover {
            cursor: pointer;
        }
    }

    &__subtitle {
        font-size: 0.8rem;
    }

    &__icon {
        margin-bottom: 10px;
        margin-left: -5px;
        width: 3rem;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;

        @media(min-width: $mediaTablet) {
            font-size: 0.8rem;
            line-height: 1.5rem;
        }

        @media(min-width: $mediaLaptop) {
            font-size: 1rem;
        }
    }

}