@import "/src/assets/variables.scss";

.opinion-banner {
  background: $themeMayaBlue;
  &_image {
    position: relative;
    height: 200px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    img.back {
      left: -150px;
      top: -160px;
    }
  }

  h2,
  p {
    display: inline-flex !important;
  }
}
