@import "/src/assets/variables.scss";

.header-tag-list {
  border-radius: 10px;
  text-align: center;
  &.check {
    background: linear-gradient(180deg, $themeLightGreen 0%, $themeGreen 100%);
  }

  &.alert {
    background: linear-gradient(
      180deg,
      $themeLightYellowGrad 0%,
      $themeYellowGrad 100%
    );
  }
}
