@import "/src/assets/variables.scss";

.collapsable-v2-container {

  &_header {
    display: flex;
    align-items: center;

    &_input-fill {
      border-radius: 50%;
      width: 4px;
      height: 4px;
      border: 1px solid transparent;

      &_active {
        background: $themeDark;
      }

      &_inactive {
        background: $themeWhite;
      }
    }

    &_input-border {
      margin-right: 10px;
      width: 9px;
      height: 9px;
      border: 1px solid $themeDark;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_base {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_title {
        display: flex;
        flex-direction: column;
        flex: 'grow';
        font-family: "Duplet";
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: auto;

        &_main {
          margin-right: auto;
        }
      }
    }

    &_upLegend {
      margin-left: auto;
      font-family: 'Duplet';
      font-style: normal;
      font-weight: 325;
      font-size: 14px;
      line-height: 17px;

      &_active {
        &::after {
          content: "-";
          font-weight: bold;
          margin-left: 10px;
        }
      }

      &_inactive {
        &::after {
          content: "+";
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }

    &_subtitle {
      font-family: "Duplet";
      font-size: 14px;
      font-weight: 325;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
}

.collapsable_active {
  display: block;
  max-height: 500px;
}