@import "/src/assets/variables.scss";

button.about {
  width: 23px;
  height: 24.5px;
  font-size: 18px;
  background-color: $themeSoftPurple;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: black;
  top: 12px;
  right: 12px;
  &::before {
    content: "";
    position: absolute;
    top: 3px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.4;
    border-radius: 5px;
    height: 12px;
  }
  &.light-button {
    top: auto;
    bottom: 20px;
    right: 20px;
    background-color: $themeWhite;
    &::before {
      background: rgba(167, 169, 219, 0.5);
    };
  }
}
