@import "_variables";
@import "_mixins";
@import "/src/assets/variables.scss";

.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

.pie {
  position: absolute;
  border: $circle-width solid $themeGreen;
  box-sizing: border-box;
  width: 1.3em;
  height: 1.3em;
  clip: rect(0em, 0.65em, 1.3em, 0em);
  border-radius: 50%;
  @include rotate(0deg);
}

.pie-fill {
  @include rotate(180deg);
}

.doughnut-chart {

  *,
  *:before,
  *:after {
    @include box-sizing(content-box);
  }

  position: relative;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  float: left;
  background-color: $themeYellowCroyola;

  &.light {
    span {
      background-color: $themeWhite;
      color: $themeDark !important;
    }
  }

  &.dark {
    span {
      background-color: $themeDark;
      color: $themeWhite !important;
    }
  }

  >span {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-size: 0.4em;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    margin: auto;

    >span {
      margin-bottom: -2px;
    }
  }

  .expected-dot {
    position: absolute;
    left: calc(50% + (sin(var(--expected-score-angle)) * 0.55em));
    top: calc(50% - (cos(var(--expected-score-angle)) * 0.55em));
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: $themeDark;
    border-radius: 50%;
    z-index: 100;
  }

  .slice {
    position: absolute;
    width: 1.3em;
    height: 1.3em;
    clip: rect(0em, 1.3em, 1.3em, 0.65em);
  }

  .bar {
    @extend .pie;
  }

  @for $i from 51 through 100 {
    &.p#{$i} {
      & .slice {
        @extend .rect-auto;
      }

      &.bar:after {
        @extend .pie-fill;
      }

      & .fill {
        @extend .pie;
        @extend .pie-fill;
      }
    }
  }

  @for $i from 1 through 100 {
    &.p#{$i} .bar {
      $degs: (360/100 * $i);
      @include rotate(#{$degs}deg);
    }
  }
}