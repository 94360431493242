@import "/src/assets/variables.scss";

.confirm-assignment {
    &__text {
        text-align: center;
        max-width: 400px;
        padding: 30px 0;
        height: auto;
    }

    &__modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;

        :first-child {
            margin-right: 20px;
        }

        .button {
            flex: 1;
        }
    }
}