$themeDark: #222222;
$themeWhite: #ffffff;
$themeGrey: #e4e6eb;
$themeLightGrey: #f7f8f9;
$themeLightGreen: #cdf0c6;
$themeGreen: #90dc80;
$themeDarkBlue: #351d52;
$themePurple: #56308d;
$themeSoftPurple: #b29cdb;
$themeLightYellow: #fff597;
$themeYellow: #ffe52e;
$themeLightYellowGrad: #f7dea6;
$themeYellowGrad: #f1c157;
$themeYellowCroyola: #ffe57d;
$themeLightOrange: #ffb043;
$themeOrange: #ff7641;
$themeRed: #ff6e83;
$themeBlue: #313f92;
$themeLightBlue: #248dd2;
$themeMayaBlue: #56ccf2;
$themeLightCyan: #c3ffff;
$themeDarkCyan: #007d98;
$mediaLaptop: "1024px";
$mediaMediumLaptop: "1280px";
$largeLaptop: "1535px";
$mediaTablet: "768px";
$mediaLargeMobile: "425px";
$mediaMediumMobile: "375px";

:export {
  themeDark: $themeDark;
  themeWhite: $themeWhite;
  themeGrey: $themeGrey;
  themeLightGrey: $themeLightGrey;
  themeLightGreen: $themeLightGreen;
  themeGreen: $themeGreen;
  themeDarkBlue: $themeDarkBlue;
  themePurple: $themePurple;
  themeSoftPurple: $themeSoftPurple;
  themeLightYellow: $themeLightYellow;
  themeYellow: $themeYellow;
  themeYellowCroyola: $themeYellowCroyola;
  themeLightOrange: $themeLightOrange;
  themeOrange: $themeOrange;
  themeRed: $themeRed;
  themeBlue: $themeBlue;
  themeLightBlue: $themeLightBlue;
  themeMayaBlue: $themeMayaBlue;
  themeLightCyan: $themeLightCyan;
  themeDarkCyan: $themeDarkCyan;
}
