.no-info {
  min-height: 200px;
  div {
    min-width: 150px;
  }
  .center-block {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
}
