.modal {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  padding: 40px 20px 20px;
  height: 100%;
}

.aboutIcon {
  @media (min-width: '1024px') {
    top: 50%;
    right: 3%;
    bottom: 50%;
    transform: translate(-50%,-50%);
  }
  @media (max-width: '1023px') {
    top: -15%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: '639px') {
    top: -7%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

}

.aboutWidget {
  @media (min-width: '1024px') {
    top: 38%;
    left: 63px;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: '1023px') {
    top: -50%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: '639px') {
    top: -30%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
}