.collapsable-container {
  &_title {
    font-weight: bold;

    &_active {
      &::after {
        content: "-";
        font-weight: bold;
      }
    }
    &_inactive {
      &::after {
        content: "+";
        font-weight: bold;
      }
    }
  }

  &_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
}

.collapsable_active {
  display: block;
  max-height: 500px;
}
