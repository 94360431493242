@import "/src/assets/variables.scss";

.button {
  box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  display: inline-flex !important;
  gap: 10px;
  border: none;
  height: 35px;
  align-items: center;
  padding: 0 16px;
  font-weight: bold;
  line-height: 1em;
  justify-content: center;
}

.dark {
  background-color: $themeDark;
  color: $themeWhite;
}

.light {
  background-color: $themeWhite;
  color: $themeDark;
}

.disabled {
  filter: contrast(50%);
}
