@import "/src/assets/variables.scss";

.past-assignment-container {
  display: grid;
  grid-template-columns: 100%;
  max-width: 95vw;

  @media (min-width: $mediaLaptop) {
    grid-template-columns: 30% 70%;
  }

  &_search {

    @media (min-width: $mediaLaptop) {
      &-box {
        display: flex;
        flex-direction: column;
      }
    }

    @media (min-width: $mediaMediumLaptop) {
      &-box {
        display: flex;
        flex-direction: row;
      }
    }

  }

  &_lists {

    &_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba($themeDark, .05);
      border-radius: 0px 0px 10px 10px;
      padding: 10px;

      p {
        font-family: 'Duplet';
        font-style: normal;
        font-weight: 325;
        font-size: 16px;
        line-height: 19px;
        opacity: 100%;
      }
    }

    &_words {
      max-height: 200px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: auto auto;

      @media (min-width: $mediaLaptop) {
        grid-template-columns: auto auto auto auto;
      }

      strong {
        font-family: "Duplet";
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }

      span {
        font-family: "Duplet";
        font-size: 12px;
        font-style: italic;
        font-weight: 325;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    &-types {
      font-style: italic;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: $themeGrey;
  }

  &_opinion {
    grid-area: opinion;
  }

  &_topic {
    font-weight: bold;

    &::after {
      content: ">";
      font-weight: bold;
    }
  }

  .create-assignment-item {
    &_active {
      background-color: $themeDark;
      color: $themeWhite !important;
    }
  }
}