@import "/src/assets/variables.scss";

.assignment-container {
    &__first-section {
        display: flex;
        flex-direction: column;

        @media(min-width: $mediaTablet) {
            flex-direction: row;
        }
    }

    &__statistics {
        width: 100%;

        @media(min-width: $mediaTablet) {
            flex-direction: row;
            width: 65%;
        }
    }
}