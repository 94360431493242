@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/variables.scss";

@font-face {
  font-family: "Duplet";
  src: url('/assets/fonts/DupletRounded-Bold.otf');
}

@font-face {
  font-family: "Duplet";
  src: url('/assets/fonts/DupletRounded-Regular.otf');
  font-weight: 300;
}

@font-face {
  font-family: "Duplet";
  src: url('/assets/fonts/DupletRounded-Semibold.otf');
  font-weight: 500;
}

@font-face {
  font-family: "Duplet";
  src: url('/assets/fonts/DupletRounded-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: "Phosphate";
  src: url("assets/fonts/Phosphatepro.otf");
}

@font-face {
  font-family: "Bakso";
  src: url("assets/fonts/BaksoSapi.otf");
}

* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $themeDark;
    border-right: 4px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    margin: 12px;
  }
}

@layer base {
  body {
    font-family: "Duplet";
    font-weight: 300;
    font-feature-settings: "ss02" 1, "ss06" 1, "ss07" 1;
    font-size: 16px;
    @media (max-width: $mediaTablet) {
      font-size: 14px;
    }
  }

  .bg-img-amazed {
    background-image: url("./assets/bg-blown.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .font-heading {
    font-weight: bold;
  }

  .heading {
    @apply font-normal font-heading;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply heading;
    color: #222222;
    font-weight: bold;
  }

  h1 {
    font-family: "Duplet";
    font-size: 2.3rem;
    line-height: 2.5rem;
    @media (max-width: $mediaLargeMobile) {
      font-size: 1.7rem;
      line-height: 1.7rem;
    }
  }

  h2 {
    font-family: "Phosphate";
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
    letter-spacing: 0.08rem;
    line-height: 2rem;
    @media (max-width: $mediaLargeMobile) {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.1rem;
  }

  p,
  strong,
  a,
  li {
    color: #222222;
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

input {
  background-color: $themeWhite;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  font-family: "Duplet";
  font-weight: bold;
  outline: 0;
  height: 42px;
  padding-left: 12px;
  color: $themeDark;
  &::placeholder {
    color: #{$themeDark}60;
  }
}

.show-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
