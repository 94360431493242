@import "/src/assets/variables.scss";

.latest-earnings {
  &_container {
    display: grid;
    @media (min-width: $mediaMediumLaptop) {
      grid-template-columns: 250px 1fr;
    }
  }
}

.earned-items {
  grid-area: items;
  overflow: auto;

  @media (min-width: $mediaLaptop) {
    height: 480px;
  }
  &_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(min-content, 220px));

    @media (min-width: $largeLaptop) {
      grid-template-columns: repeat(auto-fill, min(200px));
    }

    &_item {
      background-color: $themeLightGrey;
      border-radius: 5px;
      border: 1px solid #c4c8d0;
      border-radius: 5px;

      .image-icon {
        width: 70%;
        height: 0;
        padding-bottom: 70%;
        background-color: $themeWhite;
        border-radius: 100%;
        position: relative;
        img {
          position: absolute;
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          width: calc(100% + 20px);
          max-width: none !important;
        }
      }
    }
  }
}
