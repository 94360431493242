@import "/src/assets/variables.scss";

.initial-banner {
  background: linear-gradient(0, $themeLightBlue 0%, $themePurple 100%);
  &_container {
    background-repeat: no-repeat !important;
    background-size: auto 110% !important;
    background-position: right !important;
    min-height: 300px !important;
  }
  &_title {
    max-width: 500px;
    p {
      color: $themeWhite;
    }
    h1 {
      color: $themeWhite;
      font-family: "Phosphate";
      letter-spacing: 0.1rem;
    }
  }
}
