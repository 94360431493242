@import "/src/assets/variables.scss";

.item-list {
  &_container {
    @media (max-width: $mediaLaptop) {
      position: relative;
    }
    height: 320px;
    overflow: auto;

    &_content {
      grid-template-rows: repeat(3, 1fr);
      @media (max-width: $mediaLaptop) {
        position: absolute;
        left: 0;
        top: 0;
        overflow-x: scroll;
        width: 100%;
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-auto-columns: minmax(200px, 1fr);
        grid-auto-flow: column;
      }

      > div.item-list {
        &.check {
          background-color: #{$themeGreen}80 !important;
        }
        &.alert {
          background-color: #{$themeYellow}80;
        }
        background-color: #{$themeGrey}80;
        border-radius: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.check {
  background-color: #{$themeGreen}80;
  border-radius: 10px;
}

.alert {
  background-color: #{$themeYellow}80;
  border-radius: 10px;
}
