@import "/src/assets/variables.scss";

.stars-collected {
  &_container {
    display: grid;
    @media (min-width: $mediaMediumLaptop) {
      grid-template-columns: 250px 1fr;
    }
  }
}
