@import "/src/assets/variables.scss";

.user-header {
  background: linear-gradient(91.64deg, #6a82b9 0%, #a8aadb 100%);

  &_container {
    display: grid;
    grid-template-areas: "avatar earnings messages";
    grid-template-columns: 300px max-content minmax(200px, max-content);
    justify-content: space-around;
    @media (max-width: $mediaLaptop) {
      grid-template-areas: "avatar messages" "earnings earnings";
      grid-template-columns: 1fr 1fr !important;
      grid-template-rows: 1fr 1fr;
    }
    .avatar-container {
      grid-area: avatar;
      position: relative;
      max-width: 300px;
      @media (max-width: $mediaLaptop) {
        max-width: 400px !important;
      }
      width: 100%;
      &_content {
        position: relative;
        max-width: 300px;
        height: 100%;
        @media (max-width: $mediaLaptop) {
          max-width: 400px !important;
        }
      }
      img.back {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        width: 80%;
        z-index: 0;
        @media (max-width: $mediaLaptop) {
          max-width: 200px !important;
        }
      }
      img.avatar {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 60%;
        max-width: 100px;
        top: -10px;
        @media (max-width: $mediaLargeMobile) {
          max-width: 70px;
          top: 18px;
        }
        @media (max-width: $mediaMediumMobile) {
          max-width: 60px;
          top: auto;
          bottom: 34%;
        }
      }
      .nickname {
        h1 {
          font-family: "Bakso";
          letter-spacing: -0.15em;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          z-index: 3;
          text-align: center;
          font-size: 3vw;

          @media (max-width: $mediaLargeMobile) {
            font-size: 1.4rem;
            bottom: 20px;
          }
          @media (min-width: $mediaLargeMobile) {
            font-size: 1.8rem;
            bottom: 28px;
          }
          @media (min-width: $mediaLaptop) {
            font-size: 2rem;
            bottom: 30px;
          }
          @media (min-width: $mediaMediumLaptop) {
            font-size: 2.5rem !important;
            bottom: 40px;
          }
        }
        .gradient {
          color: white;
          background: linear-gradient(180deg, #ffe52e 0%, #ff5200 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          z-index: 5;
        }
        .gradient-shadow {
          z-index: 4;
          margin-bottom: -2px;
          @media (max-width: $mediaLargeMobile) {
            -webkit-text-stroke: 3px $themeDarkBlue;
          }
          @media (min-width: $mediaLargeMobile) {
            -webkit-text-stroke: 5px $themeDarkBlue;
          }
          @media (min-width: $mediaLaptop) {
            -webkit-text-stroke: 6px $themeDarkBlue;
          }
          @media (min-width: $mediaMediumLaptop) {
            -webkit-text-stroke: 8px $themeDarkBlue;
          }
        }
        .white-shadow {
          z-index: 3;
          margin-bottom: -2px;
          @media (max-width: $mediaLargeMobile) {
            -webkit-text-stroke: 10px $themeWhite;
          }
          @media (min-width: $mediaLargeMobile) {
            -webkit-text-stroke: 15px $themeWhite;
          }
          @media (min-width: $mediaLaptop) {
            -webkit-text-stroke: 18px $themeWhite;
          }
          @media (min-width: $mediaMediumLaptop) {
            -webkit-text-stroke: 20px $themeWhite;
          }
        }
      }
    }

    .earnings-container {
      grid-area: earnings;
      background-color: $themeWhite;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      .category-item {
        background-color: $themeGrey;
        border-radius: 5px;
        img {
          width: 3vw;
          max-width: 48px;
          @media (max-width: $mediaLaptop) {
            width: 24px;
          }
        }
      }
    }

    .messages-container {
      position: relative;
      grid-area: messages;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: $themeLightYellow;
      div {
        max-width: 100px;
        position: relative;
        img {
          width: 8vw;
          max-width: 100px;
          min-width: 64px;
        }
        span {
          position: absolute;
          right: -8px;
          top: -8px;
          background: #ff6e83;
          box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
          width: 26px;
          height: 26px;
          font-weight: bold;
          color: $themeWhite;
        }
      }
    }
  }
}

.popup-arrow {
  color: transparent;
}
