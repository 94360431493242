@import "/src/assets/variables.scss";

.faq-container {
  display: grid;
  grid-template-areas: "search" "questions" "opinion";
  @media (min-width: $mediaLaptop) {
    grid-template-areas:
      "search questions"
      "opinion .";
    grid-template-columns: min-content 1fr;
  }

  &_search {
    grid-area: search;
  }

  &_questions {
    grid-area: questions;
  }

  &_opinion {
    grid-area: opinion;
  }

  &_topic {
    font-weight: bold;
    &::after {
      content: ">";
      font-weight: bold;
    }
  }

  .faq-item {
    &_active {
      background-color: $themeDark;
      color: $themeWhite !important;
    }
  }
}
