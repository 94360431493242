@import "/src/assets/variables.scss";

main {
  display: grid;
  grid-template-areas: "time_period""main_widgets_container""earnings""words_collected""stars";
  grid-template-columns: 1fr;

  @media (min-width: $mediaLaptop) {
    grid-template-areas:
      "time_period time_period"
      "main_widgets_container words_collected"
      "stars words_collected"
      "earnings words_collected";
    grid-template-columns: 1fr minmax(340px, min-content);
  }

  .time-period-container {
    grid-area: time_period;
  }

  .create-assignment-container {
    grid-template-columns: 1fr;
  }

  .active-assignment-container {
    grid-template-columns: 1fr;
  }

  .work-on-word-games-container {
    grid-template-columns: 1fr;
    position: relative;
  }

  .earning-container {
    grid-area: earnings;
  }

  .stars-collected-container {
    grid-area: stars;
  }

  .game-performance-container {
    grid-area: performance;
  }

  .main-widgets-container {
    grid-area: main_widgets_container;
    display: flex;
    flex-direction: column;
  }

  .words-collected-container {
    grid-area: words_collected;
    display: flex;
    flex-direction: column;
  }
}