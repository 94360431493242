@import "/src/assets/variables.scss";

.tag {
  position: relative;
  min-width: 36px;
  &::after {
    content: "";
    color: white;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $themeDark;
    border: none;
    border-radius: 6px;
    transform: skewX(-15deg);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1),
      inset 1px 1px 0px rgba(255, 255, 255, 0.5),
      inset -2px -2px 0px rgba(0, 0, 0, 0.5);
  }

  .icon-tag {
    background-color: $themeWhite;
    border-radius: 100%;
    padding: 6px;
    box-shadow: 2px 2px 0 #{$themeDark}80;
    width: 42px;
    height: 42px;
  }

  span {
    font-weight: bold;
    text-align: center;
    color: $themeWhite;
    h1 {
      color: $themeWhite !important;
      margin-top: 0.3rem;
    }
  }

  span.fix-index-children {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-flex;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
}
