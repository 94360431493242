@import "/src/assets/variables.scss";

.statistics-container {
    padding-bottom: 16px;

    &__columns {
        height: 240px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .text-nowrap {
        white-space: nowrap;
    }
}