@import "/src/assets/variables.scss";

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #{$themeGrey}80;
  border-radius: 5px;
  height: 38px;
  padding: 5px;
  align-self: auto;
  margin: 10px 0;
}

.itemListWidget {
  background-color: #{$themeGrey}80;
  height: 240px !important;
}
