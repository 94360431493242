@import "/src/assets/variables.scss";

.item-list-unit {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #{$themeGrey}80;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }

    &__prev-icon {
        width: 2.25rem;
        max-width: 100%;
        height: auto;
    }

    &__post-icon {
        width: 1.5rem;
        max-width: 100%;
        height: auto;

        &.check {
            background-color: #{$themeGreen}80 !important;
        }

        &.alert {
            background-color: #{$themeYellow}80;
        }
    }
}