@import "/src/assets/variables.scss";

.list-grid {
    display: grid;
    grid-template-areas: "words_columns";
    grid-template-columns: repeat(2, 1fr);
    max-height: 300px;
    overflow-y: scroll;
    background-color: $themeWhite;

    @media (min-width: $mediaTablet) {
        grid-template-areas:
            "words_columns";
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $mediaLaptop) {
        grid-template-areas:
            "words_columns";
        grid-template-columns: repeat(4, 1fr);
    }

    &__words_columns {
        grid-area: words_columns;
    }

}